<template>
    <div>
        <h3 class="text-center">Ti abbiamo inviato un con il link per reimpostare la password.</h3>
        <p class="text-center">Controlla la tua casella</p>
    </div>
</template>

<script>

export default {
    name: "forgot-password-ok"
}
</script>